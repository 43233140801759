<template>
    <v-container grid-list-xl fluid>
      <v-btn @click="showImages=!showImages">{{!showImages?'Choisir une image existente':'Fermer'}}</v-btn>
      <v-layout row wrap v-show="showImages">
        <v-flex sm12>
          <h1>Media Object List</h1>
        </v-flex>
        <v-flex lg12>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            @update:options="onUpdateOptions"
          >
            <template v-slot:item.contentUrl="{ item }">
              <v-img v-if="isImageExtension(item.contentUrl)" contain max-height="75px" max-width="25vw" :src="getFileUrl(item.contentUrl)" @click="selectMediaObject(item)"/>
              <template v-else>{{ item.contentUrl.toUpperCase() }}</template>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import ActionCell from '../../components/ActionCell';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import MediaObjectFilterForm from "../../components/mediaobject/Filter";
import {isImageExtension} from '../../utils/filesType';
import {ENTRYPOINT} from "../../config/entrypoint";

export default {
  name: 'MediaObjectSelector',
  servicePrefix: 'MediaObject',
  mixins: [ListMixin],
  components: {
    MediaObjectFilterForm,
    Toolbar,
    ActionCell,
    DataFilter
  },
  props: ['value'],
  created() {
    this.filters = { 'order[updatedAt]':'desc'};
  },
  data() {
    return {
      showImages:false,
      headers: [
        { text: 'url', value: 'contentUrl' },
        {
          text: 'Actions',
          value: 'action',
          sortable: false
        }
      ],
      selected: []
    };
  },
  computed: {
    ...mapGetters('mediaobject', {
      items: 'list'
    }),
    ...mapFields('mediaobject', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    selectMediaObject(val){
      this.$emit('input',val);
    },
    ...mapActions('mediaobject', {
      getPage: 'fetchAll'
    }),
    isImageExtension,
    getFileUrl(path){
      return ENTRYPOINT+path
    }
  }
};
</script>
