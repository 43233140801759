<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      full-width
      max-width="580px"
      min-width="290px"
  >
    {{date}}-{{time}}
    <template v-slot:activator="{ on }">
      <v-text-field
          v-model="dateTime"
          :label="label+ ' AAAA-MM-JJ HH:MM'"
          prepend-icon="calendar-clock"
          clearable
          v-on="on"
          v-on:change="$emit('input',dateTime)"
      ></v-text-field>
    </template>
    <v-date-picker
        :first-day-of-week="1"
        locale="fr-fr"
        v-if="!showTime"
        v-model="date" @input="showTime = true"
    ></v-date-picker>
    <v-time-picker
        v-if="menu && showTime"
        v-model="time"
        full-width
        format="24hr"
        @click:minute="saveDateTime"
    ></v-time-picker>
  </v-menu>
</template>

<script>

export default {
  name: "DateTimePicker",
  props: {
    label: String,
    value: String,
  },
  data() {
    return {
      time: null,
      date: null,
      menu: false,
      showTime: false,
      momentDateTime: null,
    }
  },
  computed: {
    dateTime() {
      if (this.date && this.time)
        return this.date + ' ' + this.time
      return ''
    }
  },
  created() {
    this.setDateAndTimeFromValue()
  },
  methods: {
    saveDateTime() {
      this.menu = false
      this.showTime = false
      this.$emit('input', this.dateTime)
    },
    setDateAndTimeFromValue() {

      function padTo2Digits(num) {
        return String(num).padStart(2, '0');
      }
      console.log(this.value)
      if (this.value !== undefined) {
        this.momentDateTime = new Date(this.value);
      }
      this.time = padTo2Digits(this.momentDateTime.getHours()) + ':' + padTo2Digits(this.momentDateTime.getMinutes());
      this.date = padTo2Digits(this.momentDateTime.getFullYear()) + '-' + padTo2Digits(this.momentDateTime.getMonth() + 1) + '-' + padTo2Digits(this.momentDateTime.getDate());
    }
  },
  watch: {
    'value': function () {
      this.setDateAndTimeFromValue()
    }
  }
}
</script>

<style scoped>

</style>