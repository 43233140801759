<template>
  <v-form>
    <v-container fluid>
      <v-col cols="12" sm="6" md="6" v-if="item.slug">
        {{ item.slug }}
      </v-col>
      <v-row>
        <v-col offset-md="3" cols="1" sm="1" md="1">
          <v-checkbox
              v-model="item.visible"
              :error-messages="visibleErrors"
              :label="$t('Publié')"
              @input="$v.item.visible.$touch()"
              @blur="$v.item.visible.$touch()"
          />
        </v-col>
        <v-col cols="5" sm="5" md="5">
          <v-text-field
              v-model="item.nom"
              :error-messages="nomErrors"
              :label="$t('Titre')"
              @input="$v.item.nom.$touch()"
              @blur="$v.item.nom.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1"><span class="caption">{{item&&item.mediaObjectImage ?item.mediaObjectImage['@id']:''}}</span></v-col>
        <v-col offset-md="2" cols="11" sm="6" md="6"><media-object-selector v-model="item.mediaObjectImage"/></v-col>
        <v-col offset-md="2" cols="11" sm="6" md="6">
          <media-object v-model="item.mediaObjectImage" is-image max-height="25vh"/>
        </v-col>
      </v-row>

      <v-row>
        <!-- todo : fix the dateTime format, not supported by default on vuetify -->
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <date-time-picker v-model="item.dateDebut" :label="$t('Date et horaires de début')"></date-time-picker>
          <v-alert v-model="dateDebutErrors"></v-alert>
        </v-col>
      </v-row>
      <v-row>
        <!-- todo : fix the dateTime format, not supported by default on vuetify -->
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <date-time-picker v-model="item.dateFin" :label="$t('Date et horaires de fin')"/>
          <v-alert v-model="dateFinErrors"></v-alert>
        </v-col>
      </v-row>


      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
              v-model="item.localisation"
              :items="localisationSelectItems"
              :error-messages="localisationErrors"
              :no-data-text="$t('Aucun résultat')"
              :label="$t('Localisation')"
              item-text="nom"
              item-value="@id"
          />
        </v-col>
      </v-row>

      <v-col offset-md="3" cols="12" sm="6" md="6">
        <v-select
            v-model="item.evenement"
            :items="sortedEvents"
            :error-messages="evenementsErrors"
            :no-data-text="$t('No results')"
            :label="$t('Evenement')"
            unique
            item-text="nom"
            item-value="@id"
        />
      </v-col>

      <v-col offset-md="3" cols="12" sm="6" md="6">
        <v-select
            v-model="item.animationType"
            :items="animationTypeSelectItems"
            :error-messages="animationTypeErrors"
            :no-data-text="$t('No results')"
            :label="$t(`Type d'animation`)"
            unique
            item-text="nom"
            item-value="@id"
        />
      </v-col>

      <v-col offset-md="3" cols="12" sm="6" md="6">
        <v-select
            v-model="item.animationCategories"
            :items="animationCategorieSelectItems"
            :error-messages="animationCategorieErrors"
            :no-data-text="$t('No results')"
            :label="$t(`Catégorie d'animation`)"
            chips
            multiple
            item-text="nom"
            item-value="@id"
        />
      </v-col>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.descriptionCourt"
              :error-messages="descriptionCourtErrors"
              :label="$t('Résumé court')"
              hint="150 caractères maximum"
              persistent-hint
              @input="$v.item.descriptionCourt.$touch()"
              @blur="$v.item.descriptionCourt.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <wysiwyg
              v-model="item.descriptionComplet"
              :error-messages="descriptionCompletErrors"
              :label="$t('Description complète')"
              @input="$v.item.descriptionComplet.$touch()"
              @blur="$v.item.descriptionComplet.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="4" cols="12" sm="6" md="6">
          <v-card-title>Couleur de fond</v-card-title>
          <v-color-picker
              width="400"
              mode="hexa"
              update:mode="string"
              input="string"
              v-model="bgcolor"
              value="string"
              :error-messages="bgcolorErrors"
              @input="$v.item.bgcolor.$touch()"
              @blur="$v.item.bgcolor.$touch()"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";
import InputDate from "../InputDate";
import {date} from "../../validators/date";
import MediaObject from "../MediaObject";
import DateTimePicker from "../DateTimePicker";
import MediaObjectSelector from "../mediaobject/Selector";

export default {
  name: "AnimationForm",
  mixins: [validationMixin],
  components: {
    MediaObjectSelector,
    DateTimePicker,
    MediaObject,
    InputDate
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
    this.localisationGetSelectItems();
    this.animationTypeGetSelectItems();
    this.animationCategorieGetSelectItems();
    this.evenementsGetSelectItems();
  },
  data() {
    return {
      slug: null,
      nom: null,
      descriptionCourt: null,
      descriptionComplet: null,
      visible: null,
      bgcolor: null,
      localisation: null,
      evenements: null,
      animationType: null,
      animationCategories: null,
      motcles: null,
      image: null,
    };
  },

  watch: {
    'bgcolor': function () {
      this.item.bgcolor = this.bgcolor.hex
    },
    'item.dateDebut': function () {

      if (!this.item.dateDebut) {
        this.item.dateDebut = localStorage.getItem("lastDateDebut");
      } else {
        if (!this.item.dateFin) {
          let lastStored = localStorage.getItem("lastDateFin")
          this.item.dateFin = lastStored ? lastStored :this.item.dateDebut;
        }
        localStorage.setItem("lastDateDebut", this.item.dateDebut);
      }
    },
    'item.dateFin': function () {
      if (!this.item.dateFin) {
        this.item.dateDebut = localStorage.getItem("lastDateFin");
      } else {
        localStorage.setItem("lastDateFin", this.item.dateFin);
      }
    }
  },

  computed: {

    ...mapFields("localisation", {
      localisationSelectItems: "selectItems",
    }),
    ...mapFields("evenement", {
      evenementsSelectItems: "selectItems",
    }),
    ...mapFields('animationType', {
      animationTypeSelectItems: "selectItems",
    }),
    ...mapFields('animationCategorie', {
      animationCategorieSelectItems: "selectItems",
    }),
    sortedEvents(){
      return this.evenementsSelectItems.sort((a,b)=>{
        return b.id > a.id ? 1 : -1;
      })
    },
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },


    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, "slug") && errors.push(this.violations.slug);

      return errors;
    },
    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, "nom") && errors.push(this.violations.nom);

      return errors;
    },
    dateDebutErrors() {
      const errors = [];

      if (!this.$v.item.dateDebut.$dirty) return errors;

      has(this.violations, "dateDebut") &&
      errors.push(this.violations.dateDebut);

      return errors;
    },
    dateFinErrors() {
      const errors = [];

      if (!this.$v.item.dateFin.$dirty) return errors;

      has(this.violations, "dateFin") && errors.push(this.violations.dateFin);

      return errors;
    },
    descriptionCourtErrors() {
      const errors = [];

      if (!this.$v.item.descriptionCourt.$dirty) return errors;

      has(this.violations, "descriptionCourt") &&
      errors.push(this.violations.descriptionCourt);

      return errors;
    },
    descriptionCompletErrors() {
      const errors = [];

      if (!this.$v.item.descriptionComplet.$dirty) return errors;

      has(this.violations, "descriptionComplet") &&
      errors.push(this.violations.descriptionComplet);

      return errors;
    },
    visibleErrors() {
      const errors = [];

      if (!this.$v.item.visible.$dirty) return errors;

      has(this.violations, "visible") && errors.push(this.violations.visible);

      return errors;
    },
    bgcolorErrors() {
      const errors = [];

      if (!this.$v.item.bgcolor.$dirty) return errors;

      has(this.violations, "bgcolor") && errors.push(this.violations.bgcolor);

      return errors;
    },
    localisationErrors() {
      const errors = [];

      if (!this.$v.item.localisation.$dirty) return errors;

      has(this.violations, "localisation") &&
      errors.push(this.violations.localisation);

      return errors;
    },
    animationTypeErrors() {
      const errors = [];

      if (!this.$v.item.animationType.$dirty) return errors;

      has(this.violations, "animationType") &&
      errors.push(this.violations.animationType);

      return errors;
    },
    animationCategorieErrors() {
      const errors = [];

      if (!this.$v.item.animationCategorie.$dirty) return errors;

      has(this.violations, "animationCategorie") &&
      errors.push(this.violations.animationCategorie);

      return errors;
    },
    motclesErrors() {
      const errors = [];

      if (!this.$v.item.motcles.$dirty) return errors;

      has(this.violations, "motcles") && errors.push(this.violations.motcles);

      return errors;
    },
    imageErrors() {
      const errors = [];

      if (!this.$v.item.image.$dirty) return errors;

      has(this.violations, "image") && errors.push(this.violations.image);

      return errors;
    },

    evenementsErrors() {
      const errors = [];

      if (!this.$v.item.evenements.$dirty) return errors;

      has(this.violations, "evenements") &&
      errors.push(this.violations.evenements);

      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
    ...mapActions({
      localisationGetSelectItems: "localisation/fetchSelectItems",
      evenementsGetSelectItems: "evenement/fetchSelectItems",
      animationTypeGetSelectItems: "animationType/fetchSelectItems",
      animationCategorieGetSelectItems: "animationCategorie/fetchSelectItems"
    })

  },
  validations: {
    item: {
      slug: {},
      nom: {},
      dateDebut: {
        date
      },
      dateFin: {
        date
      },
      descriptionCourt: {},
      descriptionComplet: {},
      visible: {},
      bgcolor: {},
      evenements: {},
      localisation: {},
      animationType: {},
      animationCategorie: {},
      motcles: {},
      image: {}
    }
  },

};
</script>
